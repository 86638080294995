.media-preview-container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.media-preview-content {
    width: 100%;
    max-width: 900px;
    height: 80%;
    min-height: 50%;
    background-color: var(--color-white);
    border-radius: 16px;
}

.media-preview-content video,
.media-preview-content img,
.media-preview-content embed {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: contain;
}

.media-preview-content-audio {
    height: fit-content !important;
    min-height: fit-content !important;
    border-radius: 24px !important;
    display: flex;
    align-items: center;
}

.media-preview-content-audio audio {
    width: 100%;
}

.media-preview-content-unsupported {
    height: fit-content !important;
    min-height: fit-content !important;
    border-radius: 24px !important;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
    padding: 64px;
}

.media-preview-content-unsupported>a {
    font-size: 18px;
    color: var(--color-orange);
}

.media-preview-content-options,
.delete-dialog-options,
.icon-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}