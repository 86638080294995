.creation-preview-grid-container {
    display: grid;
    gap: 12px;
    border-top: 1px solid var(--color-black);
    border-bottom: 1px solid var(--color-black);
    padding: 16px 0;
    margin-bottom: 16px;
    width: 100%;
}
.creation-preview-grid-container-cols {
    grid-template-columns: 3fr 1fr;
}
.creation-preview-grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 12px;
}
.creation-preview-codes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 0 12px;
    border-left: 1px solid var(--color-black);
}
.creation-preview-qr-code-id {
    font-size: 15px;
    text-align: center;
}
.creation-preview-qr-code {
    height: 150px !important;
    width: 150px !important;
}

.creation-tags {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.creation-preview-container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.creation-preview {
    background-color: var(--color-white);
    padding: 64px;
    border-radius: 12px;
    width: 70%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 550px;
    overflow-y: scroll;
}

.creation-preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.creation-preview-content>table {
    width: 100%;
    text-align: left;
    margin-top: 5px;
}

.creation-preview-content>table,
.creation-preview-content th,
.creation-preview-content td {
    border: 1px solid var(--color-black);
    border-collapse: collapse;
}

.creation-preview-content td,
.creation-preview-content th {
    padding: 12px 8px;
}