.collection-card-media-container {
    width: 100%;
    position: relative;
    cursor: pointer;
}

.collection-card-media {
    width: 100% !important;
    min-width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.creation-card-media-overlay {
    background-color: var(--color-orange);
}

.overlay-button {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media (max-width: 599px) {
    .collection-card-media,
    .overlay-button {
        border-radius: 24px;
    }

}

@media(min-width: 900px) {
    .collection-card-media-container {
        height: 160px;
        max-height: 160px;
        width: 196px;
        max-width: 196px;
        min-width: 196px;
    }

    .collection-card-media,
    .overlay-button {
        border-radius: 24px;
    }
}

@media(min-width: 1200px) {
    .collection-card-media-container {
        width: 280px;
        max-width: 280px;
        min-width: 280px;
        height: 200px;
        max-height: 200px;
    }

    .collection-card-media,
    .overlay-button {
        border-radius: 24px;
    }
}