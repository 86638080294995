.litigation-card1 {
  padding: 24px 36px;
  border-radius: 24px;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 100%;
  border: 1px solid gainsboro;
}

.litigation-card-authors {
  display: grid;
  grid-template-columns: 1fr 50px 1fr;
  gap: 36px;
  width: 100%;
  padding: 12px 0;
  border-top: 1px solid var(--color-black);
  border-bottom: 1px solid var(--color-black);
  justify-content: center;
  align-items: center;
  text-align: center;
}

.litigation-card-author {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.litigation-card-author>h3 {
  margin-bottom: 12px;
}

.litigation-times {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mr-auto {
  margin-right: auto;
}

.litigation-card-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.litigation-details-link {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}

.litigation-winner-label {
  background-color: var(--color-black);
  padding: 3px 5px;
  color: var(--color-white);
  transform: skew(-10deg, 0deg);
}

.litigation-jury-members-count {
  color: var(--color-orange-dark);
}

.toggle-bar {
  width: 100%;
  border-radius: 12px;
  background-color: var(--color-orange-dark);
  margin-bottom: 24px;
  margin-top: 16px;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.toggle-bar .btn {
  color: var(--color-white) !important;
  width: 100% !important;
}

.toggle-bar .btn-active {
  color: var(--color-orange-dark) !important;
  background-color: var(--color-white) !important;
}

.m-auto {
  margin: auto;
}

.p-64 {
  padding: 64px;
}

.litigation-action-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  width: 100%;
}

.litigation-action-buttons>.btn {
  width: 100% !important;
  border-radius: 8px !important;
}