.input-error {
    border: 2px solid var(--color-red) !important;
}

.input-error-p {
    margin-top: 5px !important;
    color: var(--color-red) !important;
}

.MuiInput-root::after,
.MuiInput-root>* ::after,
.MuiInput-root::before,
.MuiInput-root>* ::before {
    border-bottom: none !important;
}