.collection-card {
  background: #FFFFFF;
  border: 1px solid #EBEFF0;
  box-shadow: 0px 10px 58px rgba(122, 97, 96, 0.31);
  width: 100%;
}

.collection-member-images{
  display: flex;
  align-items: center;
  gap: 12px;
}

.collection-member-images>img {
  display: inline;
  object-fit: cover;
  border: 1px solid #FFFFFF;
  filter: drop-shadow(4.36946px 2.91297px 24.7603px rgba(200, 209, 216, 0.35));
  border-radius: 50%;
}

.collection-member-images>img:first-of-type {
  margin-left: 0;
}

.collection-social-icon {
  fill: #F78F8A;
}

.collection-social-icon.active {
  color: #F78F8A;
}

@media (max-width: 599px) {
  .collection-card {
    padding: 18px;
    border-radius: 24px;
  }

  .collection-member-images>img {
    width: 40px;
    height: 40px;
  }
}

@media(min-width: 600px) {
  .collection-card {
    padding: 18px;
    border-radius: 18px;
  }

  .collection-member-images>img {
    width: 40px;
    height: 40px;
  }
}

@media(min-width: 900px) {
  .collection-card {
    padding: 24px;
    border-radius: 24px;
  }
}

@media(min-width: 1200px) {
  .collection-member-images>img {
    width: 50px;
    height: 50px;
  }
}

.recognition-card-link {
  display: flex;
  align-items: center;
  gap: 8px;
}