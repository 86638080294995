.litigation-vote-container {
    background-color: white;
    padding: 24px 48px;
    border-radius: 24px;
    width: fit-content;
    margin-left: auto;
}

.carousel-container {
    width: 90%;
    position: relative;
}

.carousel-container-cards {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 24px;
    z-index: 2;
}

.carousel-slide-btns-container {
    position: absolute;
    width: calc(120%);
    margin-left: -10%;
    padding: 0 15px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
}

.carousel-slide-btns-container>button {
    margin: 0 !important;
    padding: 0 !important;
    min-width: initial !important;
}

.vote-button {
    padding: 8px 12px !important;
}