.material-card {
  background: #FFFFFF;
  border: 1px solid #EBEFF0;
  box-shadow: 0px 10px 58px rgba(122, 97, 96, 0.31);
  width: 100%;
}

.material-card-image {
  width: 100%;
  object-fit: cover;
}

.material-member-images>img {
  display: inline;
  object-fit: cover;
  border: 1px solid #FFFFFF;
  filter: drop-shadow(4.36946px 2.91297px 24.7603px rgba(200, 209, 216, 0.35));
  border-radius: 50%;
}

.material-member-images>img:first-of-type {
  margin-left: 0;
}

.material-social-icon {
  fill: #F78F8A;
}

.material-social-icon.active {
  color: #F78F8A;
}

.material-card-action-btn {
  width: fit-content !important;
  min-width: initial !important;
}
.material-card-action-btn > img {
  height: 24px !important;
  width: 24px !important;
}

.material-card-action-btn-gray {
  background-color: #F5F9FD !important;
  border: 1px solid #F5F9FD !important;
}

.material-card-avatar {
  height: 56px !important;
  width: 56px !important;
  border-radius: 18px;
}

@media (max-width: 599px) {
  .material-card {
    padding: 18px;
    border-radius: 24px;
  }

  .material-card-image {
    border-radius: 24px;
  }

  .material-member-images>img {
    width: 40px;
    height: 40px;
    margin-left: -14px;
  }

  .material-card-action-btn {
    padding: 5px !important;
    border-radius: 5px !important;
  }
}

@media(min-width: 600px) {
  .material-card {
    padding: 18px;
    border-radius: 18px;
  }

  .material-member-images>img {
    width: 40px;
    height: 40px;
    margin-left: -17px;
  }

  .material-card-action-btn {
    padding: 8px !important;
    border-radius: 8px !important;
  }
}

@media(min-width: 900px) {
  .material-card {
    padding: 24px;
    border-radius: 24px;
  }

  .material-card-image {
    height: 160px;
    max-height: 160px;
    width: 196px;
    max-width: 196px;
    border-radius: 24px;
  }

  .material-card-action-btn {
    padding: 12px !important;
    border-radius: 12px !important;
  }
}

@media(min-width: 1200px) {
  .material-card-image {
    width: 200px;
    max-width: 200px;
    height: 160px;
    max-height: 160px;
    border-radius: 24px;
  }

  .material-member-images>img {
    width: 50px;
    height: 50px;
    margin-left: -24px;
  }
}
