.user-card {
  width: 100%;
  height: fit-content;
  border-radius: 24px;
  display: flex;
  gap: 24px;
  padding: 24px;
}

.user-card-primary {
  color: var(--color-white) !important;
  background: linear-gradient(104.08deg, var(--color-yellow) -28.92%, var(--color-orange) 76.85%);
  box-shadow: 0px 16.7655px 50.2966px rgba(247, 143, 138, 0.47);
}

.user-card-secondary {
  color: var(--color-black);
  background: var(--color-white);
}

.user-card-image {
  width: 70px;
  height: 70px;
  border-radius: 24px;
  object-fit: cover;
}

.user-card-details {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.user-card-details h6 {
  font-size: 22px !important;
}

.user-card-details span {
  font-size: 16px !important;
}

.user-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.user-card-action-btn {
  width: fit-content !important;
  min-width: initial !important;
}

.user-card-action-btn>img {
  height: 24px !important;
  width: 24px !important;
}

.user-card-action-btn-primary {
  background-color: rgba(245, 249, 253, 0.1) !important;
  border: 1px solid rgba(245, 249, 253, 0.3) !important;
}

.user-card-action-btn-secondary {
  background-color: var(--color-orange) !important;
  border: 1px solid var(--color-orange-20) !important;
}

.user-card-labels {
  margin-top: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
}

.user-card-labels .label {
  padding: 24px 36px;
  font-weight: 500;
}

.user-card-labels .label-primary {
  background-color: var(--color-white);
  color: var(--color-orange);
}

.user-card-labels .label-secondary {
  background-color: var(--color-orange-20);
  color: var(--color-orange);
}

@media (max-width: 599px) {
  .user-card-action-btn {
    padding: 5px !important;
    border-radius: 5px !important;
  }
}

@media(min-width: 600px) {
  .user-card-action-btn {
    padding: 8px !important;
    border-radius: 8px !important;
  }
}

@media(min-width: 900px) {
  .user-card-action-btn {
    padding: 12px !important;
    border-radius: 12px !important;
  }
}