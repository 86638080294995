.notification-card {
    box-sizing: border-box;
    height: 246px;
    background: #FFFFFF;
    border: 1.48953px solid #EBEFF0;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.notification-card:hover {
    transition: all 0.5s ease;
    box-shadow: 0px 40.2172px 86.3925px rgba(122, 97, 96, 0.31);
}

.notification-detial {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
}

.notification-btn {
    width: 145px;
    height: 50px;
    background: linear-gradient(104.08deg, #FECE76 -28.92%, #F78B88 76.85%);
    box-shadow: 0px 13px 39px rgba(247, 143, 138, 0.47);
    border-radius: 15px !important;
    color: #FFFFFF !important;
    font-family: 'ABeeZee' !important;
    font-style: normal !important;
}

.notification-title {
    width: 300px;
    height: 30px;
    font-family: 'ABeeZee';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: #272727;
}

.notification-body {
    font-family: 'ABeeZee';
    width: 323px;
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #32363C;
}

.notification-img {
    padding: 1rem;
    width: 200px;
    height: 203px;
}

.treding-card-media {
    width: 100% !important;
    min-width: 100% !important;
    height: 100% !important;
    /* object-fit: fill; */
    border-radius: 17.8743px;
}


@media only screen and (max-width: 480px) {
    .notification-card {
        border-radius: 15px;
    }

    .treding-card-media {
        border-radius: 10.163px;
    }

    .notification-img {
        width: 88.08px;
        height: 114.33px;

    }

    .notification-card {
        width: 343px;
        height: 140.59px;
    }

    .notification-title {
        font-weight: 400;
        font-size: 17.7357px;
        line-height: 30px;
    }

    .notification-body {
        font-weight: 400;
        max-width: 212.58px;
        font-size: 11.8568px;
        line-height: 140%;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .notification-btn {
        width: 95.7px;
        height: 29.64px;
        font-weight: 400 !important;
        font-size: 11.8568px !important;
        line-height: 150% !important;
        border-radius: 8.46914px !important;
    }

    .notification-detial {
        gap: 0.6rem;
    }
}