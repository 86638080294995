.litigation-vote-container {
    background-color: white;
    padding: 24px 48px;
    border-radius: 24px;
    width: fit-content;
    margin-left: auto;
}

.carousel-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 24px;
    position: relative;
}

.carousel-slide-btns-container {
    position: absolute;
    width: calc(100% + 54px);
    margin-left: -27px;
    display: flex;
    justify-content: space-between;
}

.carousel-slide-btns-container>button {
    margin: 0 !important;
    padding: 0 !important;
    min-width: initial !important;
}

.creation-author p {
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
}

.creation-author-wallet {
    background-color: lightgray;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
}

.header-lines {
    overflow: hidden;
    text-align: center;
}

.header-lines>span {
    position: relative;
    display: inline-block;
}

.header-lines>span:before,
.header-lines>span:after {
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 2px solid;
    width: 100vw;
    margin: 0 20px;
}

.header-lines>span:before {
    right: 100%;
}

.header-lines>span:after {
    left: 100%;
}