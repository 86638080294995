.litigationBox {
    border-radius: 25px;
    overflow: hidden;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px;
    width: 100%;
}

@media (min-width: 1700px) {
    .grid-container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.mr-auto {
    margin-right: auto;
}

.toggle-bar {
    width: 100%;
    border-radius: 12px;
    background-color: var(--color-orange-dark);
    margin-bottom: 24px;
    margin-top: 16px;
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.toggle-bar .btn {
    color: var(--color-white) !important;
    width: 100% !important;
}

.toggle-bar .btn-active {
    color: var(--color-orange-dark) !important;
    background-color: var(--color-white) !important;
}

.m-auto {
    margin: auto;
}

.p-64 {
    padding: 64px;
}