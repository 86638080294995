.header {
  min-height: 112px;
  max-height: 112px;
}

.site-logo {
  height: auto;
}

.site-nav ul {
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 36px;
  padding: 0px;
}

.site-nav ul li {
  padding: 12px 0px;
}

.site-nav ul li a {
  text-decoration: none;
  color: #32363C;
  font-size: 17px;
  font-weight: 400;
}

.site-popup-menu {
  background-color: #FAFBFC;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.site-popup-header {
  padding: 16px 24px;
  border-bottom: 1px solid #D9D9D9;
}


/* PHONES */
@media (max-width: 599px) {
  .header {
    min-height: 96px;
    max-height: 96px;
  }
  .site-logo {
    width: 180px;
  }
}

/* TABLETS */
@media (min-width: 600px) {
  .site-logo {
    width: 200px;
  }
}
@media (min-width: 900px) {
  .site-logo {
    /* width: 200px; */
  }
}

/* DESKTOPS */
@media (min-width: 1200px) {
  .site-logo {
    width: 200px;
  }
}

.loggedin-user {
  display: flex;
  gap:8px;
  align-items: center;
  justify-content: center;
  z-index: 20000000;
}

.loggedin-user > img {
  border-radius: 12px;
}