.container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
}



.home-title {
    font-family: 'ABeeZee';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 150%;
    color: #272727;
    padding-bottom: 0.5rem;
}

@media only screen and (max-width: 480px),
only screen and (max-device-width: 600px) {
    .home-title {
        font-size: 25px;
    }
}