.input-error {
    border: 2px solid var(--color-red) !important;
}
.input-error + p {
    margin-top: 5px !important;
    color: var(--color-red) !important;
}

.tag-input-container { 
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.tag-placeholder {
    color: rgb(169, 169, 169) !important;
}

.tag-input {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    color: #32363C !important;
}

.tag-input-container:focus-within .tag-placeholder {
    display: none;
}

.tag-input-container .tag {
    background-color: var(--color-orange) !important;
    color: var(--color-white) !important;
}

.tag-input-container svg {
    fill: var(--color-white);
}

.tag-suggestions-container {
    width: 100%;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: absolute;
    z-index: 999;
    top: 100%;
    border: 1px solid var(--color-orange);
    box-shadow: 0px 13px 39px rgb(247 143 138 / 47%);
    max-height: 200px;
    overflow-y: scroll;
}

.tag-suggestion {
    background-color: var(--color-orange);
    color: var(--color-white);
    border: 0;
    outline: 0;
    cursor: pointer;
    padding: 8px 24px;
    border-radius: 8px;
    font-size: 16px;
    text-align: left;
}
