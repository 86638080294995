.create-collection {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EBEFF0;
}

.create-collection-qrcode {
  background: #F5F9FD;
  border: 1px solid rgba(39, 39, 39, 0.24);
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-collection-verify-box {
  background: #F5F9FD !important;
  display: flex;
  justify-content: center;
  border: 1px solid rgba(39, 39, 39, 0.24) !important;
  border-radius: 12px !important;
  height: 60px;
}

.creation-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin-top: 64px;
}

/* PHONES */
@media (max-width: 599px) {
  .create-collection {
    margin-top: 10px;
    padding: 59px 60px;
    border-radius: 16px;
  }

  .create-collection-qrcode {
    width: 190px;
    height: 190px;
    border-radius: 16px;
  }

  .create-collection-qrcode>img {
    width: 160px;
    height: 160px;
  }

  .create-collection-verify-box {
    width: 180px;
  }
}

/* TABLETS */
@media (min-width: 600px) {
  .create-collection {
    margin-top: 10px;
    padding: 59px 60px;
    border-radius: 16px;
  }

  .create-collection-qrcode {
    width: 190px;
    height: 190px;
    border-radius: 16px;
  }

  .create-collection-qrcode>img {
    width: 160px;
    height: 160px;
  }

  .create-collection-verify-box {
    width: 220px;
  }
}

@media (min-width: 900px) {
  .create-collection {
    margin-top: 10px;
    padding: 59px 60px;
    border-radius: 16px;
  }

  .create-collection-verify-box {
    width: 200px;
  }
}

/* DESKTOPS */
@media (min-width: 1200px) {
  .create-collection {
    margin-top: 10px;
    padding: 59px 60px;
    border-radius: 16px;
  }

  .create-collection-qrcode {
    width: 250px;
    height: 250px;
    border-radius: 16px;
  }

  .create-collection-qrcode>img {
    width: 220px;
    height: 220px;
  }
}