.carousel {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 650px;
  perspective: 480px;
  transform-style: preserve-3d;
  transform-origin: 0% 50%;
  top: -30px;
  left: -18px;
}

.carousel.carousel-slider {
  top: 0;
  left: 0;
}

.carousel.carousel-slider .carousel-fixed-item {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 1;
}

.carousel.carousel-slider .carousel-fixed-item.with-indicators {
  bottom: 68px;
}

.carousel.carousel-slider .carousel-item {
  width: 100%;
  height: 100%;
  min-height: 600px;
  position: absolute;
  top: 0;
  left: 0;
}

.carousel.carousel-slider .carousel-item h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.carousel.carousel-slider .carousel-item p {
  font-size: 15px;
}

.carousel .carousel-item {
  visibility: hidden;
  width: 550px;
  height: 500px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1 !important;
}

.carousel .carousel-item>img {
  box-sizing: border-box;
  width: 100%;
  height: 507.93px;
  border: 4px solid #FFFFFF;
  border-radius: 22.3429px;
  object-fit: cover;
}

.carousel .indicators {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  /* bottom: -30px; */
}

.carousel .indicators .indicator-item {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 24px 4px;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  border-radius: 50%;
  background: linear-gradient(104.08deg, #f4ede0 -28.92%, #f5bab8 76.85%);
}

.carousel .indicators .indicator-item.active {
  background: linear-gradient(104.08deg, #FECE76 -28.92%, #F78B88 76.85%);
  box-shadow: 0px 13px 39px rgba(247, 143, 138, 0.47);
}

.carousel.scrolling .carousel-item .materialboxed,
.carousel .carousel-item:not(.active) .materialboxed {
  pointer-events: none;
}

@media only screen and (max-width: 480px) {
  .carousel .carousel-item>img {
    height: 380.93px;
  }

  .carousel .carousel-item {
    width: 244px !important;
    height: 327px;
    position: absolute;
    top: 0;
    left: 24px !important;
    opacity: 1;

  }
}

@media only screen and (min-width: 2048px) {
  .leftIcon {
    left: 5%;
  }

  .rightIcon {
    right: 7%;
  }
}