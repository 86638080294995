.topauthor-card {
    height: 90px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1.48953px solid #EBEFF0;
    border-radius: 17.8743px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.topauthor-card:hover {
    transition: all 0.5s ease;
    box-shadow: 0px 40.2172px 86.3925px rgba(122, 97, 96, 0.31);
}

.topauthor-image {
    padding: 0.5rem;
    align-items: center;
}

.topauthor-image>img {
    width: 60px;
    height: 59.48px;
    filter: drop-shadow(0px -1.48953px 147.463px rgba(15, 23, 42, 0.08));
    border-radius: 11.9162px;
}

.topauthor-body>h6 {
    font-family: 'ABeeZee';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #0F172A;
}

.topauthor-body>span {
    font-family: 'ABeeZee';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #32363C;
}

.topauthor-body {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 480px),
only screen and (max-device-width: 600px) {
    .topauthor-card {
        border-radius: 15px;
    }

    .topauthor-image {
        align-items: center;
        margin-top: 0.3rem;
    }
}