.backdrop {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.loader {
    position: relative;
    flex-basis: 33.333%;
}

.loader::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    transform-origin: left top;
    border-style: solid;
    border-top-color: var(--color-white);
    border-bottom-color: var(--color-black);
    border-left-color: var(--color-black);
    border-right-color: var(--color-black);
    border-radius: 50%;
    content: "";
    animation: rotate-loader 0.4s infinite linear;
}

.loader-small::before {
    width: 16px !important;
    height: 16px !important;
    border-width: 3px !important;
}

.loader-medium::before {
    width: 24px !important;
    height: 24px !important;
    border-width: 4px !important;
}

.loader-large::before {
    width: 36px !important;
    height: 36px !important;
    border-width: 5px !important;
}

@keyframes rotate-loader {
    from {
        transform: rotate(0deg) translate3d(-50%, -50%, 0);
    }

    to {
        transform: rotate(359deg) translate3d(-50%, -50%, 0);
    }
}